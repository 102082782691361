@import 'assets/css/constants.scss';

.modal-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;

  .modal {
    display: flex;
    flex-direction: column;
    max-width: 692px;
    width: 100%;
    max-height: 90vh;
    max-height: 90svh;
    gap: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 998;
    min-width: 320px;
    background-color: #fff;
    border-radius: 6px;
    padding: 32px;

    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    h2, h3 {
      display: flex;
      align-items: center;
      color: $color-main;
      justify-content: space-between;

      svg {
        cursor: pointer;
        transition: transform 0.2s ease;
        
        &:hover {
          transform: scale(1.1);
        }
      }

      svg * {
        fill: $color-main;
      }

      &.warning {
        color: $color-warning;

        svg * {
          fill: $color-warning;
        }
      }
    }

    p.modal-info {
      color: #5A5A5A;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px
    }

    .actions {
      gap: 24px;

      button {
        max-width: 212px;
        width: 100%;
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 997;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }

  &.displayed {
    display: block;
  }
}