@import 'assets/css/constants.scss';

#admin {
  position: relative;
  overflow-y: auto;

  .tools-bar {
    display: flex;
    justify-content: space-between;

    input {
      width: 400px;
    }

    .actions {
      gap: 12px;

      svg {
        background-color: #D9642C;
        cursor: pointer;
  
        * {
          fill: rgb(237, 237, 237);
        }
  
        &:hover {
          transform: scale(1.1);
        }
      }

      .loader {
        margin-right: 10px;

        .spinner {
          background: conic-gradient(rgba(0, 0, 0, 0) 10%, white);
        }
      }
  
      button {
        background-color: #D9642C;

        &:last-of-type {
          margin-left: 20px;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;

    .group {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      background-color: #F6F6F6;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      flex-wrap: wrap;
      gap: 20px;

      // .separator {
      //   width: 100%;
      //   height: 1px;
      //   background-color: #E2E7EF;

      //   &.inline {
      //     width: 1px;
      //     height: 60px;
      //     margin: 0 10px;
      //   }
      // }

      > svg {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 20px;
        top: 20px;
        background-color: #D9642C;
        padding: 6px;
        border-radius: 50%;
        cursor: pointer;

        * {
          fill: white;
        }

        &:hover {
          transform: scale(1.1);
        }
      }

      > button, .rules > button {
        margin-top: auto;
      }

      .all-rules {
        gap: 20px;
      }

      > div.rules {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
      }

      .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        padding: 16px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        > div {
          gap: 6px;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        &.separator {
          width: 240px;
        }

        &.accepted-inputs {
          > div:not(:last-of-type) {
            padding-bottom: 6px;
            margin-bottom: 1px;
            border-bottom: 1px solid #E2E7EF;
          }
        }
      }
    }
  }
}