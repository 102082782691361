@import 'assets/css/constants.scss';

#home {
  position: relative;

  .tools-bar {
    display: flex;
    justify-content: space-between;

    .input-wrapper.select {
      margin-right: auto;
      margin-left: 20px;
      align-items: flex-start;

      label {
        color: white;
      }
    }
  }

  p.no-requests {
    padding: 20px;
    margin: 37vh auto;
    max-width: 544px;
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
    vertical-align: middle;
  }
}