@import 'assets/css/constants.scss';
@import 'assets/css/fonts.scss';

* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  outline: none;

  body {
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #app {
      overflow: hidden;
      height: calc(100vh - $topbar-height);
      height: calc(100svh - $topbar-height);
      margin-top: $topbar-height;
    }
  }
}

h2 {
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
}

h3 {
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
}

.page {
  height: calc(100vh - $topbar-height);
  height: calc(100svh - $topbar-height);

  &.with-toolbar {
    padding-top: $toolbar-height;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hide-scroll {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  outline: none;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}
