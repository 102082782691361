.delete-banner {
  height: 90px;
  padding: 8px;

  &.success {
    background: #D9F5E4;
  }

  &.error {
    background: #FEE1DC;
  }
}