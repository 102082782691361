@import 'assets/css/constants.scss';

header {
  background-color: #01080E;
  height: $topbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
  padding: 0 24px;
  display: flex;
  align-items: center;

  * {
    color: white;
  }

  .logos {
    display: flex;
    gap: 18px;

    span {
      display: inline-flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .separator {
    height: 100%;
    width: 1px;
    background-color: rgb(106, 106, 106);
    margin: 0 0 0 24px;
  }

  .tabs {
    height: 100%;
  
    button.simple-button.button--borderLess {
      height: 100%;
      color: white;
      border-radius: 0;
      padding: 0 16px;

      &:hover {
        background-color: #212731;
      }
    }

    &.tab-requests {
      button:first-of-type {
        background-color: #212731;
      }
    }

    &.tab-rules {
      button:last-of-type {
        background-color: #212731;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  header .logos > img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  header {
    .logos {
      display: none;
    }
  }
}
