.drop-upload {
  display: flex;
  flex-direction: column;

  .drop-zone {
    width: 100%;
    padding: 24px;
    cursor: pointer;
    gap: 24px;
    text-align: center;
    border-radius: 8px;
    border: 1px dashed rgba(5, 13, 18, 0.39);
    transition: box-shadow 0.2s ease;

    .icon {
      position: relative;
      height: 50px;
      width: 50px;

      svg {
        &:first-of-type {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:last-of-type {
          position: absolute;
          background-color: black;
          border-radius: 50%;
          padding: 4px;
          height: 24px;
          width: 24px;
          bottom: 0px;
          right: 0px;
        }
      }
    }

    .drop-info {
      color: rgba(5, 13, 18, 0.70);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      b {
        text-decoration: underline;
      }
    }

    &:hover {
      box-shadow: 0px 0px 8px rgba(5, 13, 18, 0.161);
    }
  }
}