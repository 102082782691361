@import 'assets/css/constants.scss';

.modal-wrapper .modal {
  .request-error {
    .info {
      color: $color-error;
      width: 100%;
      display: flex;
      gap: 20px;
      align-items: center;
    }
  
    .meta {
      overflow: auto;
      max-height: 197px;
      border: 1px solid $color-error;
      color: $color-error;
      padding: 16px;
      border-radius: 4px;
    }
  }

  .folder-info {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    padding: 16px;

    > div {
      display: flex;
      flex-direction: column;
    }

    .files-count {
      font-size: 11px;
      font-weight: 800;
      background-color: white;
      position: absolute;
      top: -8px;
      padding: 0 4px;
    }

    .loader .spinner {
      width: 34px;
      height: 34px;
    }
  }
}
