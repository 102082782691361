@import 'assets/css/constants.scss';

.tools-bar {
  position: fixed;
  top: $topbar-height;
  height: $toolbar-height;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  z-index: 1;
  background-color: #212731;
}