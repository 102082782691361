.accordion {
  width: 100%;
  position: relative;

  h3 {
    font-weight: 400;
    display: flex;
    border-radius: 8px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 16px;
    background: #212731;
    border-radius: 8px;
    padding: 16px;
    color: white;
    font-weight: 600;
  }

  > svg {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 12px;
    right: 8px;
    transition: all 0.15s ease-in-out;
    pointer-events: none;

    * {
      fill: white;
    }
  }

  .wrapper {
    display: flex;
    height: 0;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;

    > * {
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }

  &.expanded {
    h3 {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    > svg {
      transform: rotate(180deg);
    }

    .wrapper {
      height: auto;
      overflow: visible;
    }
  }
}