.create-group-auto-complete {
  position: relative;

  .results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px 0;
    max-height: 200px;

    .item {
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}